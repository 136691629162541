$(document).ready(function () {
    $('.thumbnail').on('click', function () {
        var src = $(this).attr('src');
        var isVideo = $(this).is('video');

        if (isVideo) {
            var videoElement = $('<video class="img-fluid main-media" id="main-media" controls>').attr('src', src);
            $('#main-media').replaceWith(videoElement);
        } else {
            var imgElement = $('<img class="img-fluid main-media" id="main-media" alt="Main Media">').attr('src', src);
            $('#main-media').replaceWith(imgElement);
        }
    });
});
